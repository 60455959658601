.App {
  text-align: center;
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
  height: 100%;
}

.location_bubble {
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  height: 60px;
  font-size: 20px;
  border-radius: 50px;
  top: 0;
  left: 0;
  background-color: #2441ab;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.lazy-load-image-background {
  color: inherit !important;
}
